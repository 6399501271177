<template>
	<div class="box-card">
		<div style="margin-bottom: 10px"><i class="card-line"></i>审批</div>
		<div>

			<el-table :data="data.tableData" border style="width: 100%"
				:header-cell-style="{ background: '#F8F8F8', color: '#03032C' }">
				<el-table-column prop="id" label="基金代码" />
				<el-table-column prop="fundName" label="基金名称" />
				<el-table-column prop="groupName" label="申请小组" />
				<el-table-column prop="createDate" label="申请时间" />
				<el-table-column label="操作">
					<template #default="scope">
						<el-button type="text" class="btn-primary" @click.stop.native="getFundInfo(scope.row)">详情</el-button>
						<el-button type="text" class="btn-danger" @click="openOpinion(scope.row)">意见</el-button>
					</template>

				</el-table-column>
			</el-table>
		</div>
	</div>

	<el-dialog v-model="data.details" title="详情：" width="50%">
		<div>
			<div style="margin-left: 50px;">
				<el-row>
					<el-col>
						<div style="margin-top: 20px;">基金名称：{{ data.info.fundName }}</div>
					</el-col>
					<el-col>
						<div style="margin-top: 20px;">申请小组：{{ data.info.groupName }}</div>
					</el-col>
					<el-col>
						<div style="margin-top: 20px;" @click="dw(data.info.file.fileUrl, data.info.file.fileName)">下载招募说明书：
							<span><a href="#">{{ data.info.file.fileName }}</a></span>
						</div>
					</el-col>
				</el-row>
			</div>
			<el-button style="margin-top: 20px; float:right;" type="primary" size="mini"
				@click="toInfo()">查看基金运作详情</el-button><br><br>
		</div>
	</el-dialog>

	<el-dialog v-model="opinion" title="意见内容" width="50%">
		<div>
			单个用户最多持仓金额：
			<el-input v-model="restrictions" size="mini" maxlength="9" type="number"
				></el-input>
		</div>
		<div>
			意见内容：
			<el-input v-model="opinionText" maxlength="100" show-word-limit autosize type="textarea" />
		</div>


		<template #footer>
			<span class="dialog-footer">
				<el-button @click="approve(2)">驳回</el-button>
				<el-button type="primary" @click="approve(1)">通过</el-button>
			</span>
		</template>

	</el-dialog>
</template>
<script setup>
import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import { ElMessage, ElLink } from 'element-plus';
import { useRouter } from "vue-router";
import { listCourseOpenFundInApplication, openFundApproved, openFundInfo } from './fundApi.js'

let opinion = ref(false)
let tabrow = ref({})
let router = useRouter()

let data = reactive({
	fundName: '一组基金',
	details: ref(false),
	tableData: [],
	vueExample: {},
	info: {
		"file": {
			"fileName": "",
			"fileType": "",
			"fileUrl": ""
		},
		"fundName": "",
		"groupName": ""
	}
})

var opinionText = ref('');
var restrictions = ref('');

onMounted(() => {
	listTable();
	data.vueExample = getCurrentInstance()
})

function dw(fileUrl, fileName) {
	let url = "/tradelab/documentSharing/download/openFunddFile"
	let params = {
		fileName: fileName,
		url: fileUrl
	}
	const { proxy } = data.vueExample
	proxy.$DownloadAsExcel(url, params, fileName)
}

function listTable() {
	var formData = new FormData();
	formData.append("courseId", sessionStorage.getItem('classId'));
	listCourseOpenFundInApplication(formData).then(res => {
		data.tableData = res.data.data;
	});
}

function approve(type) {
	if (opinionText.value.length == 0) {
		ElMessage({ type: 'error', message: '请填写意见！' })
		return;
	}
	let parm = {
		"id": tabrow.value.id,
		"applyStatus": type,
		"acid": tabrow.value.acid,
		"rejectionReason": opinionText.value,
		"restrictions": restrictions.value,
		"groupId": tabrow.value.groupId
	};
	openFundApproved(parm).then(res => {
		if (res.data.code == '200') {
			ElMessage({ type: 'success', message: '审核完成！' })
			restrictions.value = ''
			opinionText.value = ''
			listTable();
		} else {
			ElMessage({ type: 'error', message: res.data.msg })
			listTable();
		}
		opinion.value = false;
	})
};

// function input() {
// 	if (restrictions.value > 100000000) {
// 		restrictions.value = 100000000
// 	}
// }

function openOpinion(row) {
	restrictions.value = ''
	opinionText.value = ''
	opinion.value = true;
	tabrow.value = row;
};

function getFundInfo(row) {
	var formData = new FormData();
	formData.append("fundId", row.id);
	openFundInfo(formData).then(res => {
		data.info.fundName = res.data.data.fundName;
		data.info.groupName = res.data.data.groupName;
		data.info.file.fileName = res.data.data.files[0].fileName;
		data.info.file.fileType = res.data.data.files[0].fileType;
		data.info.file.fileUrl = res.data.data.files[0].fileUrl;
	});
	tabrow.value = row;
	data.details = true;
};

function toInfo() {
	sessionStorage.setItem("acid", tabrow.value.acid);
	sessionStorage.setItem("groupId", tabrow.value.groupId);
	sessionStorage.setItem("courseUserId", "1");
	router.push("/course/home");
}
</script>

<style lang="less" scoped>
.card-line {
	display: inline-block;
	width: 3px;
	height: 13px;
	border-radius: 8px;
	margin-right: 10px;
	background: #3B6EFB;
}

.dialog-footer {
	margin-right: 10px;
}
</style>